<template>
  <div class="white">
    <v-container fluid class="pa-0">
      <v-row align="center" justify="space-between" style="min-height: 87px;">
        <v-col cols="auto" class="py-0 d-flex justify-space-between">
          <v-btn v-if="!isSimpleState && isAdmin" class="ml-4" icon @click.stop="nav = !nav">
            <v-icon color="black">mdi-menu</v-icon>
          </v-btn>
          <a href="/" class="logo ml-6">
            <img src="@/assets/images/logo.svg" alt="Fraktus"/>
          </a>
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <v-select
            solo
            dense
            hide-details
            v-model="locale"
            :items="languageItems"
            item-text="text"
            item-value="lang"
            class="locale-selection"
            item-color="primary lighten-2"
            style="width: 120px"
          >
            <template v-slot:item="{ item, on }">
              <v-divider class="mb-2"></v-divider>
              <v-list-item class="px-0">
                <img class="selection-logo" :src="item.logo" alt="flag">
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
                <v-icon v-if="locale === item.lang" class="ml-4" size="20" color="primary">mdi-check</v-icon>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <img class="selection-logo" :src="item.logo" alt="flag">
              <span class="text-caption">{{ item.text }}</span>
            </template>
          </v-select>
          <v-tooltip v-if="!isSimpleState && (isCompany || isCustomer)" bottom color="black">
            <template v-slot:activator="{ on, attrs }">
              <a :href="helpLink" target="_blank" class="help-link mr-3">
                <v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
              </a>
            </template>
            <span>{{ 'help'|localize }}</span>
          </v-tooltip>
          <v-menu v-if="!isSimpleState" bottom left offset-y transition="slide-y-transition" nudge-left="10">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" size="63" tile color="primary" class="ml-3">
                <v-progress-circular v-if="logoutLoading" size="20" width="2" color="white" indeterminate/>
                <v-icon v-else size="24" dark>mdi-account-circle</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item link v-if="isCompany" :to='{name: "company-profile"}'>
                <v-list-item-title>{{ 'companyProfile'|localize }}</v-list-item-title>
              </v-list-item>
              <v-list-item link v-if="isCompany" :to='{name: "company-account"}'>
                <v-list-item-title>{{ 'myAccount'|localize }}</v-list-item-title>
              </v-list-item>
              <v-list-item link v-if="isCustomer" :to='{name: "account"}'>
                <v-list-item-title>{{ 'myAccount'|localize }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logOut">
                <v-list-item-title>{{ 'logout'|localize }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <v-divider/>
    <v-navigation-drawer v-model="nav" width="304" absolute temporary overlay-opacity="0.32">
      <v-list-item class="py-2 px-0">
        <v-btn class="ml-4" icon @click.stop="nav = !nav">
          <v-icon color="black">mdi-menu</v-icon>
        </v-btn>
        <a href="/" class="logo ml-6">
          <img src="@/assets/images/logo.svg" alt="Fraktus"/>
        </a>
      </v-list-item>
      <v-divider></v-divider>
      <div class="subtitle-2 mt-4 ml-6 mb-3">{{ 'adminArea'|localize }}</div>
      <v-list dense>
        <v-list-item
          v-for="item in navItems"
          :key="item.name"
          :to="item.path"
          :disabled="item.disabled"
          link
          class="px-6"
          color="primary"
          :ripple="false"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="mr-6">
              <v-icon :color="active ? 'primary' : 'silver-chalice'">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="body-1" :class="[item.disabled ? 'grey--text text--lighten-1' : 'black--text']">{{ item.name }}</div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import methods from '@/helpers/methods';
  import api from '@/api/api';
  import localize from '@/filters/localize';

  export default {
    name: 'Header',

    data() {
      return {
        nav: false,
        logoutLoading: false,
        languageItems: [
          {
            lang: 'en',
            text: 'English',
            logo: require('@/assets/images/flag-en.svg'),
          },
          {
            lang: 'se',
            text: 'Swedish',
            logo: require('@/assets/images/flag-se.svg'),
          }],
        isSimpleState: this.$router.currentRoute.meta['simpleHeader']
      };
    },

    computed: {
      isCompany() {
        return this.$store.getters.isCompany;
      },
      isCustomer() {
        return this.$store.getters.isCustomer;
      },
      isAdmin() {
        return this.$store.getters.isAdmin;
      },
      locale: {
        get() {
          return this.$store.getters.locale;
        },
        set(newValue) {
          let userData = JSON.parse(localStorage.getItem('userData'));
          this.$store.commit('changeLocale', newValue);
          localStorage.setItem('locale', newValue);
          if (userData) {
            api.updateUserSettings({locale: newValue});
          }
        },
      },
      helpLink() {
        if (this.isCompany) {
          if (this.locale === 'se') return 'https://fraktus.se/wp-content/uploads/Produktblad/Adhoc/fraktus-adhocsystemlathund-leverantorsve.pdf';
          if (this.locale === 'en') return 'https://fraktus.se/fraktus-adhoc-system_supplier_eng';
        }
        if (this.isCustomer) return 'https://fraktus.se/fraktus-adhocsystem_lathund-kund_sve/';
        return '';
      },
      navItems() {
        return [
          {
            icon: 'mdi-hand-coin',
            name: localize('requests'),
            path: {name: 'admin-requests'}
          },
          {
            icon: 'mdi-currency-eur',
            name: localize('marginSettings'),
            path: {name: 'price-settings'}
          },
          {
            icon: 'mdi-cog',
            name: localize('usersRelevancy'),
            path: {name: 'users-relevancy'}
          },
          {
            icon: 'mdi-truck-delivery',
            name: localize('companies'),
            path: {name: 'admin-companies'}
          },
          {
            icon: 'mdi-account-multiple',
            name: localize('clients'),
            path: {name: 'clients'},
          },
          {
            icon: 'mdi-finance',
            name: localize('dashboard'),
            path: 'path',
            disabled: true
          },
        ];
      },
    },

    methods: {
      async logOut() {
        try {
          this.logoutLoading = true;
          await api.logout();
          methods.logOut();
          this.logoutLoading = false;
        } catch (e) {
          this.logoutLoading = false;
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
  .logo {
    display: block;
    width: 101px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .selection-logo {
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: auto;
    margin-right: 8px;
  }

  .locale-selection {
    ::v-deep .v-input__slot {
      box-shadow: none !important;
    }
  }

  .help-link {
    display: block;
    text-decoration: none;
  }
</style>
