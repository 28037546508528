<template>
  <v-overlay v-if="checkLoading" :opacity="1" color="alabaster">
    <v-progress-linear indeterminate style="width: 100vw" color="primary" :height="2" :rounded="true"/>
  </v-overlay>
  <div v-else class="main-wrapper">
    <Header ref="header" id="header"/>
    <div class="flex-grow-1">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Header from '@/components/common/Header';
  import Footer from '@/components/common/Footer';
  import api from '@/api/api';

  let checkToken;

  export default {
    name: 'Home',
    components: {Footer, Header},

    data() {
      return {
        checkLoading: false
      };
    },

    async beforeMount() {
      this.checkLoading = true;
      await this.initCheckToken();
      checkToken = setInterval(async () => {
        await this.initCheckToken();
      }, 60000);
    },

    beforeDestroy() {
      clearInterval(checkToken);
    },

    methods: {
      async initCheckToken() {
        try {
          await api.checkToken();
          this.checkLoading = false;
        } catch (e) {}
      }
    }
  };
</script>
